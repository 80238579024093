var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-1 overflow-hidden"},[_c('portal',{attrs:{"to":"page-title"}},[_c('div',{staticClass:"flex flex-row space-x-2"},[_c('span',[_vm._v("Configure Retrieval Query:")]),(_vm.retrievalQuery)?_c('span',{staticClass:"mr-2 font-medium text-primary-700"},[_vm._v(_vm._s(_vm.retrievalQuery.title))]):_vm._e()])]),_c('portal',{attrs:{"to":"loading"}},[(_vm.loading || _vm.loadingAssets)?_c('OrbitSpinner',{attrs:{"animation-duration":1000,"size":32,"color":"#5C69D1"}}):_vm._e()],1),_c('portal',{attrs:{"to":"info-panel"}},[(_vm.tabs && _vm.tabs.length > 0)?_c('WizardTabs',{attrs:{"steps":_vm.tabs,"compact":"","activeTab":_vm.currentTab}}):_vm._e()],1),_c('portal',{attrs:{"to":"page-actions"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.currentTab === 0),expression:"currentTab === 0"}],staticClass:"flex items-center space-x-2"},[_c('span',{staticClass:"rounded-md shadow-sm sm:ml-3"},[_c('button',{staticClass:"inline-flex items-center px-4 py-2 text-sm font-medium leading-5 transition duration-150 ease-in-out bg-white border rounded-md text-primary-700 border-primary-500 hover:text-primary-500 focus:outline-none focus:ring-blue focus:border-blue-300 active:text-primary-800 active:bg-primary-50 disabled:cursor-not-allowed disabled:opacity-50",attrs:{"type":"button","disabled":_vm.loading || _vm.loadingAssets || !_vm.hasChanges},on:{"click":_vm.save}},[_c('svg',{staticClass:"w-5 h-5 mr-2 -ml-1",attrs:{"fill":"currentColor","viewBox":"0 0 20 20"}},[_c('path',{attrs:{"d":"M15.173 2H4c-1.101 0-2 .9-2 2v12c0 1.1.899 2 2 2h12c1.101 0 2-.9 2-2V5.127L15.173 2zM14 8c0 .549-.45 1-1 1H7c-.55 0-1-.451-1-1V3h8v5zm-1-4h-2v4h2V4z"}})]),_vm._v(" Save ")])])])]),_c('div',{staticClass:"flex flex-col flex-1 overflow-hidden"},[_c('div',{staticClass:"flex flex-1 overflow-hidden"},[(
                    _vm.retrievalQuery &&
                    _vm.configuration &&
                    _vm.assets &&
                    _vm.hiddenSelectedAssetIds.length === _vm.hiddenSelectedAssets.length
                )?_c(_vm.tabComponent,{tag:"component",attrs:{"uid":_vm.id,"assets":_vm.assets,"totalResults":_vm.totalResults,"accessibility":_vm.accessibility,"loading":_vm.loading || _vm.loadingAssets,"hasChanges":_vm.hasChanges,"allAssets":_vm.allAssets,"fileUrls":_vm.assetFileUrls},on:{"next":_vm.next,"refetch":_vm.refetch,"update-search-query":_vm.updateSearchQuery},model:{value:(_vm.configuration),callback:function ($$v) {_vm.configuration=$$v},expression:"configuration"}}):_c('div',{staticClass:"flex flex-col items-center flex-1 my-auto"},[_c('div',{staticClass:"flex flex-col items-center space-y-4"},[_c('SelfBuildingSquareSpinner',{attrs:{"animation-duration":5000,"size":45,"color":"#a0aec0"}}),_c('div',{staticClass:"text-lg text-neutral-600"},[_vm._v("Fetching retrieval query...")])],1)])],1),_c('div',{staticClass:"flex flex-grow-0 flex-shrink-0 bg-white border-t border-neutral-200"},[_c('div',{staticClass:"flex flex-row justify-between w-full p-4"},[(_vm.currentTab > 0)?_c('TwButton',{staticClass:"float-left",attrs:{"size":"sm","color":"secondary"},on:{"click":_vm.previous}},[_c('svg',{staticClass:"w-5 h-5 mr-1 -ml-2",attrs:{"viewBox":"0 0 20 20","fill":"currentColor"}},[_c('path',{attrs:{"fill-rule":"evenodd","d":"M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z","clip-rule":"evenodd"}})]),_vm._v(" Previous ")]):_c('div',[_c('div',{staticClass:"flex flex-row w-full space-x-4"},[_c('portal-target',{attrs:{"name":"wizard-query-summary"}})],1)]),_c('div',{staticClass:"flex flex-row items-center space-x-4"},[_c('portal-target',{attrs:{"name":"wizard-next-button-warning"}}),_c('portal-target',{attrs:{"name":"wizard-next-button"}})],1)],1)])]),_c('portal',{attrs:{"to":"modals"}},[(
                _vm.retrievalQuery &&
                [_vm.RetrievalStatus.Deprecated, _vm.RetrievalStatus.Unavailable].includes(_vm.retrievalQuery.status)
            )?_c('AssetsUnavailableModal',{on:{"remove-unavailable-assets":_vm.saveAndRefetch}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }